<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Templates'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Template Edit'"
      :title="'Template Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="openCommentModal">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
        <div v-if="langList.length" class="form-create__langs">
          <div
            v-for="lang in langList"
            :key="lang.id"
            @click="openLangModal(lang)"
            class="form-create__lang"
          >
            {{ lang.seoName }}
          </div>
        </div>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="openCommentModal">
          <div class="form-create__form-container">
            <div class="form-create__form-top">
              <div class="form-create__form-main">
                <p class="form-create__label">Name</p>
                <div class="input-container">
                  <InputText
                    :onChange="setName"
                    :id="'name'"
                    :label="'name'"
                    :type="'text'"
                    :value="formDataToSend.name"
                    :placeholder="'Name'"
                    :error-messages="validationErrors['name']"
                    isBordered
                  />
                </div>

                <p class="form-create__label">SEO Name (for URL)</p>
                <div class="input-container">
                  <InputText
                    v-if="formDataToSend.everPublished"
                    :onChange="setName"
                    :id="'seo_name'"
                    :label="'seo_name'"
                    :value="formDataToSend.seo_name"
                    :type="'text'"
                    :placeholder="'Seo Name'"
                    :error-messages="validationErrors['seoName']"
                    readonly
                    isBordered
                  />
                  <InputText
                    v-else
                    :onChange="setSeoName"
                    :id="'seo_name'"
                    :label="'seo_name'"
                    :value="formDataToSend.seo_name"
                    :type="'text'"
                    :placeholder="'Seo Name'"
                    :error-messages="validationErrors['seoName']"
                    isBordered
                  />
                </div>

                <div v-if="!isManager" class="input-container">
                  <MainCheckbox :label="'Premium'" :id="'premium'" :set-value="formDataToSend" />
                </div>
                <div v-if="isAdmin" class="input-container">
                  <MainCheckbox
                    :label="'Noindex. Attention!!!'"
                    :id="'noindex'"
                    :set-value="formDataToSend"
                  />
                </div>
                <div v-if="!isManager">
                  <p class="form-create__label">Preview picture in the product card</p>
                  <p v-if="imageSize" class="form-create__label">
                    Preview: {{ imageSize.preview.width }}:{{ imageSize.preview.height }}
                  </p>
                  <div class="input-container">
                    <div class="form-create__images">
                      <div
                        v-for="(image, index) in formDataToSend.img"
                        :key="image.id"
                        class="form-create__image"
                      >
                        <picture-input
                          v-if="
                            formDataToSend.img[index].file == null &&
                            formDataToSend.imgUrl[index].urlImg == null
                          "
                          :ref="`pictureInput_${index}`"
                          @change="handleChoosePhoto(index)"
                          accept="image/jpeg,image/png"
                          size="100"
                          width="220"
                          height="220"
                          :name="`image_${index}`"
                          buttonClass="change-image__button"
                          :customStrings="{ drag: '+', change: 'Change' }"
                        />
                        <img
                          v-if="formDataToSend.img[index].file != null"
                          :id="`blah_${index}`"
                          src="#"
                          class="form-create__image-load"
                          alt="load"
                        />
                        <a
                          v-if="formDataToSend.imgUrl[index].urlImg != null"
                          target="_blank"
                          :href="formDataToSend.imgUrl[index].urlImg"
                        >
                          <img
                            class="form-create__image-load"
                            v-if="formDataToSend.imgUrl[index].urlImg != null"
                            :src="formDataToSend.imgUrl[index].urlImg"
                            alt="Not load img"
                          />
                        </a>
                        <div
                          @click="handleChangeImage(index)"
                          v-if="
                            formDataToSend.img[index].file != null ||
                            formDataToSend.imgUrl[index].urlImg != null
                          "
                          class="form-create__change-image"
                        >
                          <RestoreIcon />
                        </div>
                        <div
                          v-if="index != 0"
                          @click="handleDeleteImage(index)"
                          class="form-create__delete-image"
                        >
                          <CancelIcon />
                        </div>
                      </div>
                      <div @click="handleAddImage" class="form-create__add-image">
                        <CancelIcon />
                      </div>
                    </div>

                    <Notification :error-messages="validationErrors['images']" />
                  </div>

                  <p class="form-create__label">
                    Big Preview picture in the product card (from Goodocs and ElegantFlyer) <br />
                    *without resizing
                  </p>
                  <div class="input-container">
                    <div class="form-create__images">
                      <div
                        v-for="(image, index) in formDataToSend.preview"
                        :key="image.id"
                        class="form-create__image"
                      >
                        <picture-input
                          v-if="
                            formDataToSend.preview[index].file == null &&
                            formDataToSend.previewUrl[index].urlPreview == null
                          "
                          :ref="`previewInput_${index}`"
                          @change="handleChoosePreview(index)"
                          accept="image/jpeg,image/png"
                          size="100"
                          width="220"
                          height="220"
                          :name="`preview_${index}`"
                          buttonClass="change-image__button"
                          :customStrings="{ drag: '+', change: 'Change' }"
                        />
                        <img
                          v-if="formDataToSend.preview[index].file != null"
                          :id="`blah-preview_${index}`"
                          src="#"
                          class="form-create__image-load"
                          alt="load"
                        />
                        <a
                          v-if="formDataToSend.previewUrl[index].urlPreview != null"
                          :href="formDataToSend.previewUrl[index].urlPreview"
                          target="_blank"
                        >
                          <img
                            class="form-create__image-load"
                            v-if="formDataToSend.previewUrl[index].urlPreview != null"
                            :src="formDataToSend.previewUrl[index].urlPreview"
                            alt="Not load image"
                          />
                        </a>
                        <div
                          @click="handleChangePreview(index)"
                          v-if="
                            formDataToSend.preview[index].file != null ||
                            formDataToSend.previewUrl[index].urlPreview != null
                          "
                          class="form-create__change-image"
                        >
                          <RestoreIcon />
                        </div>
                        <div
                          v-if="index != 0"
                          @click="handleDeletePreview(index)"
                          class="form-create__delete-image"
                        >
                          <CancelIcon />
                        </div>
                      </div>
                      <div @click="handleAddImagePreview" class="form-create__add-image">
                        <CancelIcon />
                      </div>
                    </div>

                    <Notification :error-messages="validationErrors['preview']" />
                  </div>

                  <p class="form-create__label">Download GIF</p>
                  <div class="input-container">
                    <div class="form-create__images">
                      <div
                        v-for="(image, index) in formDataToSend.gif"
                        :key="image.id"
                        class="form-create__image"
                      >
                        <picture-input
                          v-if="
                            formDataToSend.gif[index].file == null &&
                            formDataToSend.gifUrl[index].urlGif == null
                          "
                          :ref="`gifInput_${index}`"
                          @change="handleChooseGif(index)"
                          accept="image/gif"
                          size="100"
                          width="220"
                          height="220"
                          :name="`gif_${index}`"
                          buttonClass="change-image__button"
                          :customStrings="{ drag: '+', change: 'Change' }"
                        />
                        <img
                          v-if="formDataToSend.gif[index].file != null"
                          :id="`blah-gif_${index}`"
                          src="#"
                          class="form-create__image-load"
                          alt="load"
                        />
                        <img
                          class="form-create__image-load"
                          v-if="formDataToSend.gifUrl[index].urlGif != null"
                          :src="formDataToSend.gifUrl[index].urlGif"
                          alt="Not load gif"
                        />
                        <div
                          @click="handleChangeGif(index)"
                          v-if="
                            formDataToSend.gif[index].file != null ||
                            formDataToSend.gifUrl[index].urlGif != null
                          "
                          class="form-create__change-image"
                        >
                          <RestoreIcon />
                        </div>
                        <div
                          v-if="index != 0"
                          @click="handleDeleteGif(index)"
                          class="form-create__delete-image"
                        >
                          <CancelIcon />
                        </div>
                      </div>
                      <div @click="handleAddImageGif" class="form-create__add-image">
                        <CancelIcon />
                      </div>
                    </div>

                    <Notification :error-messages="validationErrors['gif']" />
                  </div>

                  <p class="form-create__label">
                    Download Slider Images (Template page. From ElegantFlyer)
                  </p>
                  <div class="input-container">
                    <div class="form-create__images">
                      <div
                        v-for="(image, index) in formDataToSend.slider"
                        :key="image.id"
                        class="form-create__image"
                      >
                        <picture-input
                          v-if="
                            formDataToSend.slider[index].file == null &&
                            formDataToSend.sliderUrl[index].urlSlider == null
                          "
                          :ref="`sliderInput_${index}`"
                          @change="handleChooseSlider(index)"
                          accept="image/jpeg,image/png"
                          size="100"
                          width="220"
                          height="220"
                          :name="`slider_${index}`"
                          buttonClass="change-image__button"
                          :customStrings="{ drag: '+', change: 'Change' }"
                        />
                        <img
                          v-if="formDataToSend.slider[index].file != null"
                          :id="`blah-slider_${index}`"
                          src="#"
                          class="form-create__image-load"
                          alt="load"
                        />
                        <img
                          class="form-create__image-load"
                          v-if="formDataToSend.sliderUrl[index].urlSlider != null"
                          :src="formDataToSend.sliderUrl[index].urlSlider"
                          alt="Not load slide"
                        />
                        <div
                          @click="handleChangeSlider(index)"
                          v-if="
                            formDataToSend.slider[index].file != null ||
                            formDataToSend.sliderUrl[index].urlSlider != null
                          "
                          class="form-create__change-image"
                        >
                          <RestoreIcon />
                        </div>
                        <div
                          v-if="index != 0"
                          @click="handleDeleteSlider(index)"
                          class="form-create__delete-image"
                        >
                          <CancelIcon />
                        </div>
                      </div>
                      <div @click="handleAddImageSlider" class="form-create__add-image">
                        <CancelIcon />
                      </div>
                    </div>

                    <Notification :error-messages="validationErrors['slider']" />
                  </div>

                  <p class="form-create__label">Download video</p>
                  <p v-if="successUploadVideo" class="form-create__label">
                    Successful video upload
                  </p>
                  <div class="input-container upload-instruction">
                    <input
                      @change="handleLoadVideo"
                      type="file"
                      name="video"
                      id="video"
                      accept="*,.mp3,.mp4,.mov,.ogg"
                    />
                    <label
                      v-if="formDataToSend.video == null"
                      class="upload-instruction__button"
                      for="video"
                      >Load</label
                    >
                    <label v-else class="upload-instruction__button" for="video">Change</label>
                    <label
                      v-if="formDataToSend.videoUrl != null"
                      class="upload-instruction__button"
                      @click="handleDeleteVideo"
                      >Delete</label
                    >
                    <Notification :error-messages="validationErrors['video']" />
                  </div>
                </div>

                <div v-else>
                  <div v-if="formDataToSend.imgUrl[0]">
                    <img
                      class="form-create__image-load"
                      v-if="formDataToSend.imgUrl[0].urlImg != null"
                      :src="formDataToSend.imgUrl[0].urlImg"
                      alt="Image"
                    />
                  </div>
                </div>

                <p class="form-create__label">Annotation</p>
                <div class="input-container">
                  <TextEditor
                    :set-value="formDataToSend"
                    :id="'annotation'"
                    :placeholder="''"
                    :error-messages="validationErrors['annotation']"
                  />
                </div>
              </div>
              <div class="form-create__form-said">
                <div v-if="!isLeadDesigner">
                  <p class="form-create__label">Status</p>
                  <div class="input-container">
                    <MainSelect
                      :id="'status'"
                      :placeholder="'Выбрать'"
                      :option-list="templatesStatusesList"
                      :set-value="formDataToSend"
                      :value="formDataToSend.status"
                      :error-messages="validationErrors['status']"
                      :disabled="!isAdmin"
                    />
                  </div>
                </div>
                <div v-else>
                  <template v-if="template && formDataToSend.status.id == 2">
                    <p class="form-create__label">Main Status</p>
                    <div class="form-create__button-lead">
                      <div @click="handleLead(8)" class="form-create__button-lead-approve">
                        Approve
                      </div>
                      <div @click="handleLead(3)" class="form-create__button-lead-reject">
                        Reject
                      </div>
                    </div>

                    <div @click="openKeywordModal()" class="form-create__button-lead-keyword">
                      Keyword Detail
                    </div>
                  </template>
                </div>

                <template v-if="template && formDataToSend.secondaryStatus == 2">
                  <p class="form-create__label">Update Status</p>
                  <div class="form-create__button-lead">
                    <div @click="handleLeadUpdate(6)" class="form-create__button-lead-approve">
                      Approve
                    </div>
                    <div @click="handleLeadUpdate(3)" class="form-create__button-lead-reject">
                      Reject
                    </div>
                  </div>

                  <div @click="openKeywordModal()" class="form-create__button-lead-keyword">
                    Keyword Detail
                  </div>
                </template>

                <template v-if="!isLeadDesigner">
                  <p class="form-create__label">Publish at</p>
                  <div class="input-container">
                    <date-picker
                      v-model="formDataToSend.date"
                      type="datetime"
                      :value-type="'DD.MM.YYYY HH:mm:ss'"
                      :show-second="false"
                      :format="'DD.MM.YYYY HH:mm:ss'"
                      :title-format="'DD.MM.YYYY HH:mm:ss'"
                      :disabled="!isAdmin"
                      placeholder="dd.mm.yyy --:--"
                    ></date-picker>
                    <Notification :error-messages="validationErrors['time']" />
                  </div>
                </template>

                <div v-if="!isManager">
                  <p class="form-create__label">Categories</p>
                  <!-- <p class="form-create__label-spans">
                    <span
                      class="form-create__label-span"
                      v-for="(category, index) in formDataToSend.categoriesLevelName"
                      :key="index"
                      >{{ category }}</span
                    >
                  </p> -->
                  <div
                    v-if="getSiteID != 5"
                    class="input-container checkbox-container checkbox-container_margin"
                  >
                    <InputCategoryTemplate
                      :set-value="formDataToSend"
                      :id="'categories'"
                      :id-level="'categoriesLevel'"
                      :parent="'categoriesParent'"
                      :values="categoriesTree"
                      :name="'name'"
                      :value="'id'"
                      :handle-search="handleSearch"
                      :search-trigger="searchTrigger"
                    />
                  </div>
                  <div v-else class="input-container checkbox-container checkbox-container_margin">
                    <InputCategory
                      :set-value="formDataToSend"
                      :id="'categories'"
                      :values="categoriesTree"
                      :name="'name'"
                      :value="'id'"
                    />
                  </div>
                  <Notification :error-messages="validationErrors['categories']" />

                  <p class="form-create__label">Template Types</p>
                  <div class="input-container checkbox-container">
                    <InputRadio
                      :set-value="formDataToSend"
                      :id="'types'"
                      :values="templatesTypesList"
                      :name="'name'"
                      :value="'id'"
                    />
                    <Notification :error-messages="validationErrors['typeId']" />
                  </div>
                </div>

                <p class="form-create__label">Template Styles</p>
                <div class="input-container checkbox-container">
                  <InputRadio
                    :set-value="formDataToSend"
                    :id="'style'"
                    :values="templatesStyleList"
                    :name="'name'"
                    :value="'id'"
                  />
                  <Notification :error-messages="validationErrors['styleId']" />
                </div>

                <p class="form-create__label">Template Colors</p>
                <div class="input-container checkbox-container">
                  <div
                    v-for="item in templatesColorList"
                    :key="item.id"
                    class="checkbox-container__item"
                  >
                    <MainCheckbox
                      :label="item.name"
                      :id="item.id + ''"
                      :set-value="formDataToSend.color"
                    />
                  </div>
                </div>

                <div v-if="!isManager">
                  <p class="form-create__label">Template Formats</p>
                  <div class="input-container checkbox-container">
                    <div
                      v-for="item in templatesFormatsList"
                      :key="item.id"
                      class="checkbox-container__item"
                    >
                      <MainCheckbox
                        :label="item.name"
                        :id="item.id + ''"
                        :set-value="formDataToSend.formats"
                        :name="'formats'"
                      />
                    </div>
                  </div>

                  <p class="form-create__label">Template Tags</p>
                  <div class="input-container checkbox-container">
                    <div
                      v-for="item in templatesTagsList"
                      :key="item.id"
                      class="checkbox-container__item"
                    >
                      <MainCheckbox
                        :label="item.name"
                        :id="item.id + ''"
                        :set-value="formDataToSend.tags"
                        :name="'name'"
                      />
                    </div>
                  </div>

                  <p class="form-create__label">Template Fonts</p>
                  <div class="input-container">
                    <InputSearch
                      :search="getFontsFilter"
                      :id="'search'"
                      :label="'search'"
                      :placeholder="'search...'"
                    />
                  </div>
                  <div class="input-container checkbox-container">
                    <div
                      v-for="item in templatesFontsList"
                      :key="item.id"
                      class="checkbox-container__item"
                    >
                      <div :title="item.url" class="form-create__row-item">
                        {{ item.name }}
                        <div @click="addFont(item)" class="form-create__row-add">
                          <CancelIcon />
                        </div>
                      </div>
                    </div>
                  </div>

                  <p class="form-create__label">Selected Fonts</p>
                  <div
                    v-if="formDataToSend.fonts.length > 0"
                    class="input-container checkbox-container"
                  >
                    <div
                      v-for="(item, index) in formDataToSend.fonts"
                      :key="item.id"
                      class="checkbox-container__item"
                    >
                      <div :title="item.url" class="form-create__row-item">
                        {{ item.name }}
                        <div @click="deleteFont(index)" class="form-create__row-delete">
                          <CancelIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-else class="input-container">No Chosen Fonts...</p>

                  <p class="form-create__label">Instruction</p>
                  <div class="input-container upload-instruction">
                    <input
                      @change="handleLoadInstruction"
                      type="file"
                      name="instruction"
                      id="instruction"
                      accept="*,.pdf"
                    />
                    <label
                      v-if="formDataToSend.file == null"
                      class="upload-instruction__button"
                      for="instruction"
                      >Load</label
                    >
                    <label v-else class="upload-instruction__button" for="instruction"
                      >Change</label
                    >
                    <label
                      v-if="formDataToSend.fileUrl != null"
                      class="upload-instruction__button"
                      @click="handleDeleteInstruction"
                      >Delete</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isManager" class="form-create__from-bottom">
              <p class="form-create__label">
                File name to FTP (ATTENTION! Fill only when you want change the archive file) <br />
                For ElegantFlyer*
              </p>
              <p class="form-create__label">
                Old file name to DO: {{ formDataToSend.oldFileName || "file is missing" }}
              </p>
              <div v-if="formDataToSend.oldFileName" class="form-create__btn">
                <MainButton @click.native="getFile">Download DO file</MainButton>
              </div>
              <div class="input-container form-create__file-ftp">
                <InputText
                  :set-value="formDataToSend"
                  :id="'fileToFTP'"
                  :label="'fileToFTP'"
                  :type="'text'"
                  :placeholder="'File name to FTP'"
                  :error-messages="validationErrors['fileName']"
                  isBordered
                />
              </div>
              <div v-if="!formDataToSend.file_name.length" class="form-create__btn">
                <MainButton
                  :buttonDisabled="
                    formDataToSend.fileToFTP.length && formDataToSend.seo_name.length ? false : true
                  "
                  @click.native="transferFile"
                  >Load to DO</MainButton
                >
              </div>
              <p v-if="formDataToSend.file_name.length" class="form-create__label">
                File name to DO: {{ formDataToSend.file_name }}
              </p>
              <p class="form-create__label">Youtube video</p>
              <div class="form-create__sizes">
                <div class="form-create__sizes-item">
                  <p class="form-create__label">Title video</p>
                </div>
                <div class="form-create__sizes-item">
                  <p class="form-create__label">Video code</p>
                </div>
              </div>
              <div
                v-for="(item, index) in formDataToSend.videos"
                :key="item.id"
                class="form-create__sizes"
              >
                <div class="form-create__sizes-item">
                  <InputText
                    :set-value="formDataToSend.videos[index]"
                    :id="'title'"
                    :label="'title'"
                    :type="'text'"
                    :placeholder="''"
                    isBordered
                  />
                </div>
                <div class="form-create__sizes-item">
                  <InputText
                    :set-value="formDataToSend.videos[index]"
                    :id="'url'"
                    :label="'url'"
                    :type="'text'"
                    :placeholder="''"
                    isBordered
                  />
                </div>
                <div @click="handleDeleteRowVideo(index)" class="form-create__sizes-delete">
                  <CancelIcon />
                </div>
              </div>
              <div class="form-create__add-size">
                <MainButton @click.native="handleAddRowVideo">Add more videos</MainButton>
              </div>

              <p class="form-create__label">Template sources</p>
              <div class="form-create__sizes">
                <div class="form-create__sizes-item">
                  <p class="form-create__label">Name source</p>
                </div>
                <div class="form-create__sizes-item">
                  <p class="form-create__label">Source Size</p>
                </div>
                <div class="form-create__sizes-item">
                  <p class="form-create__label">SRC for google drive</p>
                </div>
                <div class="form-create__sizes-item">
                  <p class="form-create__label">Load File in DO</p>
                </div>
              </div>
              <div v-for="(item, index) in formDataToSend.sizes" :key="index">
                <div class="form-create__sizes">
                  <div class="form-create__sizes-item">
                    <InputText
                      :set-value="formDataToSend.sizes[index]"
                      :id="'name'"
                      :label="'name'"
                      :type="'text'"
                      :placeholder="''"
                      isBordered
                    />
                  </div>
                  <div class="form-create__sizes-item">
                    <MainSelect
                      :id="'size'"
                      :placeholder="'Choose'"
                      :option-list="templatesSizesList"
                      :set-value="formDataToSend.sizes[index]"
                      :value="formDataToSend.sizes[index].size"
                    />
                  </div>
                  <div class="form-create__sizes-item">
                    <InputText
                      :set-value="formDataToSend.sizes[index]"
                      :id="'src'"
                      :label="'src'"
                      :type="'text'"
                      :placeholder="''"
                      isBordered
                    />
                  </div>
                  <div class="form-create__sizes-item">
                    <div
                      class="form-create__sizes-item-file"
                      v-if="formDataToSend.sizes[index].file_name != null"
                    >
                      {{ formDataToSend.sizes[index].file_name }}
                    </div>
                    <div class="upload-instruction">
                      <input
                        @change="handleLoadSourceFile(index)"
                        type="file"
                        :name="`source_file_${index}`"
                        :id="`source_file_${index}`"
                        accept=".docs, .docx, .doc, .ppt, .xls, .xlsx, .pptx"
                      />
                      <label
                        v-if="formDataToSend.sizes[index].file == null"
                        class="upload-instruction__button"
                        :for="`source_file_${index}`"
                        >Load</label
                      >
                      <label v-else class="upload-instruction__button" :for="`source_file_${index}`"
                        >Change</label
                      >
                      <label
                        v-if="formDataToSend.sizes[index].file_name != null"
                        class="upload-instruction__button"
                        @click="handleDeleteSourceFile(index)"
                        >Delete</label
                      >
                    </div>
                  </div>
                  <div
                    v-if="index != 0"
                    @click="handleDeleteRow(index)"
                    class="form-create__sizes-delete"
                  >
                    <CancelIcon />
                  </div>
                </div>
                <Notification :error-messages="validationErrors[`sources${index}FileName`]" />
                <Notification :error-messages="validationErrors[`sources${index}Name`]" />
                <Notification :error-messages="validationErrors[`sources${index}SizeId`]" />
                <Notification :error-messages="validationErrors[`sources${index}Source`]" />
              </div>

              <div class="form-create__add-size">
                <MainButton @click.native="handleAddRow">Add source</MainButton>
              </div>
            </div>
            <div class="form-create__btn">
              <MainButton :type="'submit'">Update</MainButton>
            </div>
            <div v-if="template && !template.hasLayout" class="form-create__btn">
              <MainButton @click.native="handleCreateEditorLayout">Create Editor Layout</MainButton>
            </div>
            <div v-if="template && template.hasLayout" class="form-create__btn">
              <MainButton @click.native="handleGoToEditorLayout">Go to Editor Layout</MainButton>
            </div>
          </div>
        </form>
        <div class="form-create__comments">
          <div class="form-create__comments-title">History</div>
          <div v-if="history" class="form-create__comments-container">
            <div v-for="item in history" :key="item.id" class="form-create__comment">
              <div class="form-create__comment-date">
                {{ getDate(item.createdAt) }}
              </div>
              <div v-if="item.createdBy[0]" class="form-create__comment-item">
                <b>User:</b> {{ item.createdBy[0].email }}
              </div>
              <div class="form-create__comment-item"><b>Status:</b> {{ item.status.name }}</div>
              <div class="form-create__comment-item"><b>Comment:</b> {{ item.comment }}</div>
            </div>
          </div>
          <div v-else>
            <p>No history</p>
          </div>
        </div>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <MainModal v-if="langModal" :on-close="closeLangModal" :modal-center="true">
      <div class="lang-modal">
        <div class="lang-modal__title">Translations for {{ chosenLang.name }}</div>
        <form @submit.prevent="handleSubmitLang">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSendLang"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :placeholder="'name'"
              isBordered
            />
            <div @click="handleTranslate('name', chosenLang.name)" class="form-create__translate">
              Translate (Chat GPT)
            </div>
          </div>
          <p class="form-create__label">Annotation</p>
          <div class="input-container">
            <TextEditor :set-value="formDataToSendLang" :id="'annotation'" :placeholder="''" />
            <div
              @click="handleTranslate('annotation', chosenLang.name)"
              class="form-create__translate"
            >
              Translate (Chat GPT)
            </div>
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Update</MainButton>
          </div>
        </form>
      </div>
    </MainModal>
    <MainModal v-if="keyword && keywordModal" :on-close="closeKeywordModal" :modal-center="true">
      <div class="keyword-modal">
        <div class="keyword-modal__title">Keyword - {{ keyword.keyword }}</div>
        <div class="keyword-modal__list">
          <div v-if="template.contentPlanItem.comment" class="keyword-modal__list-item">
            <b>Comment:</b> {{ template.contentPlanItem.comment }}
          </div>
          <div v-if="template.contentPlanItem.isNewProduct" class="keyword-modal__list-item">
            <b>Is new product:</b> {{ template.contentPlanItem.isNewProduct ? "yes" : "no" }}
          </div>
          <div
            v-if="template.contentPlanItem.suggestedTemplateName"
            class="keyword-modal__list-item"
          >
            <b>Suggested Template Name:</b> {{ template.contentPlanItem.suggestedTemplateName }}
          </div>
          <div v-if="keyword.templateDiagram" class="keyword-modal__list-item">
            <b>Template Diagram:</b> {{ keyword.templateDiagram }}
          </div>
          <div v-if="keyword.templateRequirements" class="keyword-modal__list-item">
            <b>Template Requirements:</b> {{ keyword.templateRequirements }}
          </div>
          <div v-if="keyword.informationMustHave" class="keyword-modal__list-item">
            <b>Information must have:</b> {{ keyword.informationMustHave }}
          </div>
          <div v-if="keyword.resume" class="keyword-modal__list-item">
            <b>Key Points:</b> {{ keyword.keyPoints }}
          </div>
          <div v-if="keyword.informationСanBeAdded" class="keyword-modal__list-item">
            <b>Information can be added:</b> {{ keyword.informationСanBeAdded }}
          </div>
          <div v-if="keyword.examples" class="keyword-modal__list-item">
            <b>Examples:</b>
            <ul>
              <li v-for="(item, index) in keyword.examples" :key="index">{{ item }}</li>
            </ul>
          </div>
          <div v-if="keyword.pages" class="keyword-modal__list-item">
            <b>Pages:</b> {{ keyword.pages }}
          </div>
          <div v-if="keyword.targetAudience" class="keyword-modal__list-item">
            <b>Target audience:</b> {{ keyword.targetAudience }}
          </div>
          <div v-if="keyword.previewRequirements" class="keyword-modal__list-item">
            <b>Preview requirements:</b> {{ keyword.previewRequirements }}
          </div>
          <div v-if="keyword.plannedLaborIntensityInHours" class="keyword-modal__list-item">
            <b>Planned labor intensity in hours:</b> {{ keyword.plannedLaborIntensityInHours }}
          </div>
          <div v-if="keyword.generalRequirements" class="keyword-modal__list-item">
            <b>General Requirements:</b>
            <div class="keyword-modal__list-item-req" v-html="keyword.generalRequirements"></div>
          </div>
          <div v-if="keyword.aiRequirements" class="keyword-modal__list-item">
            <b>AI Requirements:</b>
            <div class="keyword-modal__list-item-req" v-html="keyword.aiRequirements"></div>
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="successModal" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">Template successfully edited.</div>
    </MainModal>
    <MainModal
      v-if="commentModal"
      :on-close="
        () => {
          this.commentModal = false;
        }
      "
      :modal-center="true"
    >
      <div class="list__confirm">
        <div>
          <div class="list__confirm-text">
            Are you sure you want to save? Feel free to leave a comment
          </div>
        </div>

        <div class="input-container">
          <InputTextarea
            :set-value="formDataToSend"
            :id="'comment'"
            :label="'comment'"
            :placeholder="'Comment'"
            isBordered
          />
        </div>

        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton @click.native="closeCommentModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleSubmit" :button-cancel="true">SAVE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal
      v-if="errorModal"
      :onClose="
        () => {
          errorModal = false;
        }
      "
      modalCenter
    >
      <div class="error-img">{{ errorModalText }}</div></MainModal
    >
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import PictureInput from "vue-picture-input";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import ROUTE from "~/constants/routes";
import referencesApi from "~/api/references";
import templatesApi from "~/api/templates";
import langApi from "~/api/lang";
import { mapState } from "vuex";
import { seoTransform } from "~/helpers/seoTransform";

import "vue2-datepicker/index.css";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import MainModal from "../../components/molecules/MainModal.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainSelect from "~/components/atoms/inputs/MainSelect.vue";
import InputRadio from "~/components/atoms/inputs/InputRadio.vue";
import Notification from "~/components/molecules/Notification.vue";
import InputCategoryTemplate from "~/components/atoms/inputs/InputCategoryTemplate.vue";
import InputCategory from "~/components/atoms/inputs/InputCategory.vue";
import CancelIcon from "~/assets/images/icons/cancel-icon.svg";
import RestoreIcon from "../../assets/images/icons/restotre.svg";
import TextEditor from "~/components/atoms/inputs/TextEditor.vue";
import { getSite } from "~/helpers/userAuth";
import InputSearch from "~/components/atoms/inputs/InputSearch.vue";
import { getStoredUserInfo } from "~/helpers/userAuth";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";

export default {
  name: "TemplateEdit",
  metaInfo: {
    title: "Template Edit",
  },
  data() {
    return {
      isAdmin: false,
      isManager: false,
      search: "",
      searchTrigger: false,
      successUploadVideo: false,
      isLeadDesigner: false,
      keyword: null,
      keywordModal: false,
      formDataToSend: {
        noindex: false,
        everPublished: true,
        oldFileName: null,
        annotation: "",
        status: null,
        secondaryStatus: null,
        premium: false,
        categories: null,
        categoriesLevel: [],
        categoriesLevelName: [],
        categoriesParent: null,
        types: null,
        style: null,
        formats: {},
        color: {},
        tags: {},
        fonts: [],
        name: "",
        seo_name: "",
        fileToFTP: "",
        file_name: "",
        sizes: [
          {
            name: "",
            size: null,
            size_id: null,
            src: "",
            file: null,
            file_name: null,
          },
        ],
        videos: [
          {
            title: "",
            url: "",
            id: 2323,
          },
        ],
        date: null,
        img: [],
        imgUrl: [],
        gif: [],
        gifUrl: [],
        preview: [],
        previewUrl: [],
        slider: [],
        sliderUrl: [],
        file: null,
        fileUrl: null,
        video: null,
        videoUrl: null,
        comment: "",
      },
      deleteTurn: [],
      deleteTurnInstruction: [],
      deleteTurnVideo: [],
      deleteTurnGif: [],
      deleteTurnSourceFile: [],
      successModal: false,
      errorModal: false,
      errorModalText: "",
      imageSize: null,
      commentModal: false,
      history: [],
      formDataToSendLang: {
        name: "",
        annotation: "",
        id: null,
      },
      langEdit: false,
      langModal: false,
      chosenLang: null,
      template: null,
    };
  },
  components: {
    MainButton,
    InputText,
    PictureInput,
    MainCheckbox,
    ModalError,
    MainSelect,
    InputRadio,
    DatePicker,
    Notification,
    InputCategoryTemplate,
    InputCategory,
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    CancelIcon,
    RestoreIcon,
    MainModal,
    TextEditor,
    InputSearch,
    InputTextarea,
  },
  mounted() {
    if (getStoredUserInfo()) {
      const user = getStoredUserInfo();
      for (let i = 0; user.groups.length > i; i++) {
        if (user.groups[i].id == 1 || user.groups[i].id == 2) {
          this.isAdmin = true;
        }

        if (user.groups[i].id == 5) {
          this.isLeadDesigner = true;
        }

        if (user.groups[i].id == 59) {
          this.isManager = true;
        }
      }
    }
    this.getCategoriesList();
    this.getTypes();
    this.getStyle();
    this.getItemTemplate();
    this.getImageSizes();
    if (!this.isLeadDesigner) {
      this.getLang();
    }
    this.getSizes();
  },
  computed: {
    ...mapState("templates", ["error", "loadingProcess", "validationErrors"]),
    ...mapState("categories", ["categoriesTree"]),
    ...mapState("references", [
      "templatesStatusesList",
      "templatesTypesList",
      "templatesFormatsList",
      "templatesTagsList",
      "templatesFontsList",
      "templatesStyleList",
      "templatesColorList",
      "templatesSizesList",
    ]),
    ...mapState("lang", ["lang", "langList"]),
    getFirstLink() {
      return ROUTE.TEMPLATES_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
    getSiteID() {
      return getSite().id;
    },
  },
  methods: {
    getDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
    openCommentModal() {
      this.commentModal = true;
    },
    closeCommentModal() {
      this.commentModal = false;
    },
    async handleSubmit() {
      console.log("save");
      const data = {
        name: this.formDataToSend.name,
        annotation: this.formDataToSend.annotation,
        seo_name: this.formDataToSend.seo_name,
        premium: this.formDataToSend.premium,
        noindex: this.formDataToSend.noindex,
        images: [],
        // created_by: this.user.id,
      };

      let sliderImages = [];
      if (this.formDataToSend.sliderUrl.length > 0) {
        const arraySlider = this.formDataToSend.sliderUrl;
        for (let i = 0; arraySlider.length > i; i++) {
          if (arraySlider[i].imageId != null) {
            sliderImages.push(arraySlider[i].imageId);
          }
        }
      }
      if (sliderImages.length > 0) {
        data.slide_images = sliderImages;
      }

      if (this.formDataToSend.file_name.length) {
        data.file_name = this.formDataToSend.file_name;
      }

      let images = [];
      if (this.formDataToSend.imgUrl.length > 0) {
        const arrayImages = this.formDataToSend.imgUrl;
        for (let i = 0; arrayImages.length > i; i++) {
          if (arrayImages[i].imageId != null) {
            images.push(arrayImages[i].imageId);
          }
        }
      }
      if (images.length > 0) {
        data.images = images;
      }

      if (this.deleteTurn.length) {
        data.deleteImage = this.deleteTurn;
      }

      if (this.formDataToSend.gifUrl.length > 0) {
        const arrayGif = this.formDataToSend.gifUrl;
        for (let i = 0; arrayGif.length > i; i++) {
          if (arrayGif[i].url != null) {
            data.images.push(arrayGif[i].gifId);
          }
        }
      }

      if (this.formDataToSend.previewUrl.length > 0) {
        const arrayPreview = this.formDataToSend.previewUrl;
        for (let i = 0; arrayPreview.length > i; i++) {
          if (arrayPreview[i].url != null) {
            data.images.push(arrayPreview[i].imageId);
          }
        }
      }

      if (this.formDataToSend.fileUrl != null) {
        data.instruction = this.formDataToSend.fileUrl;
      }

      if (this.formDataToSend.videoUrl != null) {
        data.video = this.formDataToSend.videoUrl;
      }

      if (this.formDataToSend.status != null) {
        if (this.isAdmin || this.isManager || this.isLeadDesigner) {
          data.status_id = this.formDataToSend.status.id;
        } else {
          data.status_id = 2;
        }
      }

      if (this.formDataToSend.secondaryStatus != null) {
        data.secondary_status_id = this.formDataToSend.secondaryStatus;
      }

      let user_id = null;
      if (getStoredUserInfo()) {
        const user = getStoredUserInfo();
        user_id = user.id;
      }
      data.history = [
        {
          comment: this.formDataToSend.comment,
          created_by: user_id,
          status_id: data.status_id,
        },
      ];

      // if (this.formDataToSend.status != null) {
      //   if (
      //     +this.formDataToSend.status.id === 6 &&
      //     (this.formDataToSend.date == null || this.formDataToSend.date === "Invalid date")
      //   ) {
      //     this.$store.commit("templates/setValidationErrors", {
      //       time: ["Fill in the time field!"],
      //     });
      //     return false;
      //   }
      // }

      if (this.formDataToSend.date != null) {
        data.published_at = moment(this.formDataToSend.date, "DD-MM-YYYY HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss",
        );
      }

      let categoriesSend = [];
      if (this.formDataToSend.categoriesLevel.length > 0) {
        categoriesSend = this.formDataToSend.categoriesLevel;
      } else if (this.formDataToSend.categories != null) {
        categoriesSend.push(this.formDataToSend.categories);
      }
      if (categoriesSend.length > 0) {
        data.categories = categoriesSend;
      }

      const formats = Object.keys(this.formDataToSend.formats);
      if (formats.length) {
        let formatsSend = [];
        for (let i = 0; formats.length > i; i++) {
          if (this.formDataToSend.formats[formats[i]] == true) {
            formatsSend.push(formats[i]);
          }
        }
        if (formatsSend.length > 0) {
          data.formats = formatsSend;
        }
      }

      const color = Object.keys(this.formDataToSend.color);
      if (color.length) {
        let colorSend = [];
        for (let i = 0; color.length > i; i++) {
          if (this.formDataToSend.color[color[i]] == true) {
            colorSend.push(color[i]);
          }
        }
        if (colorSend.length > 0) {
          data.colors = colorSend;
        }
      }

      const tags = Object.keys(this.formDataToSend.tags);
      if (tags.length) {
        let tagsSend = [];
        for (let i = 0; tags.length > i; i++) {
          if (this.formDataToSend.tags[tags[i]] == true) {
            tagsSend.push(tags[i]);
          }
        }
        if (tagsSend.length > 0) {
          data.tags = tagsSend;
        }
      }

      const fonts = this.formDataToSend.fonts;
      const fontsSend = [];
      if (fonts.length > 0) {
        for (let i = 0; fonts.length > i; i++) {
          fontsSend.push(fonts[i].id);
        }
      }
      if (fontsSend.length > 0) {
        data.fonts = fontsSend;
      }

      if (this.formDataToSend.types != null) {
        data.type_id = this.formDataToSend.types;
      }

      if (this.formDataToSend.style != null) {
        data.style_id = this.formDataToSend.style;
      }

      const sizesSend = [];
      const sizes = this.formDataToSend.sizes;
      for (let i = 0; sizes.length > i; i++) {
        if (sizes[i].size != null || sizes[i].name.length) {
          if (sizes[i].id) {
            sizesSend.push({
              name: sizes[i].name,
              source: sizes[i].src,
              id: sizes[i].id,
              size_id: sizes[i]?.size?.id,
              file_name: sizes[i].file_name,
            });
          } else {
            sizesSend.push({
              name: sizes[i].name,
              source: sizes[i].src,
              size_id: sizes[i]?.size?.id,
              file_name: sizes[i].file_name,
            });
          }
        }
      }

      data.sources = sizesSend;

      const videosSend = [];
      const videos = this.formDataToSend.videos;
      for (let i = 0; videos.length > i; i++) {
        if (videos[i].url.length > 0) {
          videosSend.push(videos[i]);
        }
      }
      if (this.formDataToSend.videos.length > 0) {
        data.videos = videosSend;
      } else {
        data.videos = [];
      }

      this.$store.commit("templates/setLoading", true);
      const url = `/${this.getId}`;
      try {
        templatesApi
          .editTemplate(url, data)
          .then((res) => {
            if (res.success) {
              this.$store.commit("templates/setError", {});
              this.$store.commit("templates/setValidationErrors", {});
              this.openSuccessModal();
              this.commentModal = false;
            }
          })
          .catch((error) => {
            const { data } = error.response;
            this.$store.commit("templates/setError", data);
            if (data.errors) {
              this.$store.commit("templates/setValidationErrors", data.errors);
            }
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      } catch (error) {
        console.log(error);
      }
    },

    transferFile() {
      this.$store.commit("templates/setLoading", true);
      try {
        templatesApi
          .transferFile({
            fileName: this.formDataToSend.fileToFTP,
            seo_name: this.formDataToSend.seo_name,
          })
          .then((res) => {
            if (res.success) {
              this.$store.commit("templates/setError", {});
              this.$store.commit("templates/setValidationErrors", {});
              this.formDataToSend.file_name = res.data.link;
            }
            this.$store.commit("templates/setLoading", false);
          })
          .catch((error) => {
            const { data } = error.response;
            this.$store.commit("templates/setError", data);
            if (data.errors) {
              this.$store.commit("templates/setValidationErrors", data.errors);
            }
            this.$store.commit("templates/setLoading", false);
          });
      } catch (e) {
        console.log(e);
        this.$store.commit("templates/setLoading", false);
      }
    },

    getFile() {
      this.$store.commit("templates/setLoading", true);
      try {
        templatesApi
          .getFile({
            fileName: this.formDataToSend.oldFileName,
          })
          .then((res) => {
            if (res.success) {
              this.$store.commit("templates/setError", {});
              this.$store.commit("templates/setValidationErrors", {});
              location.href = res.data.link;
            }
            this.$store.commit("templates/setLoading", false);
          })
          .catch((error) => {
            const { data } = error.response;
            this.$store.commit("templates/setError", data);
            if (data.errors) {
              this.$store.commit("templates/setValidationErrors", data.errors);
            }
            this.$store.commit("templates/setLoading", false);
          });
      } catch (e) {
        console.log(e);
        this.$store.commit("templates/setLoading", false);
      }
    },

    handleLoadSourceFile(index) {
      if (this.formDataToSend.sizes[index].file_name != null) {
        this.handleDeleteSourceFile(index);
      }
      this.formDataToSend.sizes[index].file = document.querySelector(
        `input[name="source_file_${index}"]`,
      ).files[0];
      let data = new FormData();
      data.append("file", this.formDataToSend.sizes[index].file);
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .templateSourceFileUpload(data)
        .then((res) => {
          this.formDataToSend.sizes[index].file_name = res.data.path;
        })
        .catch((error) => {
          console.log(error);
          this.formDataToSend.sizes[index].file = null;
          this.formDataToSend.sizes[index].file_name = null;
          this.errorModal = true;
          this.errorModalText = "You can't upload Source File. Error";
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },

    addFont(item) {
      if (!this.formDataToSend.fonts.find((font) => font.id === item.id)) {
        this.formDataToSend.fonts.push(item);
      }
    },

    deleteFont(index) {
      this.formDataToSend.fonts.splice(index, 1);
    },

    openSuccessModal() {
      this.successModal = true;
    },

    handleChoosePhoto(index) {
      setTimeout(() => {
        this.formDataToSend.img[index].file = document.querySelector(
          `input[name="image_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.img[index].file);

        this.$store.commit("templates/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.img[index].file);
        templatesApi
          .templateImagesUpload(data)
          .then((res) => {
            this.formDataToSend.imgUrl[index].url = Object.values(res.data)[0].fileName;
            this.formDataToSend.imgUrl[index].imageId = Object.values(res.data)[0].imageId;
          })
          .catch((error) => {
            this.formDataToSend.img[index].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      }, 0);
    },

    handleChooseGif(index) {
      setTimeout(() => {
        this.formDataToSend.gif[index].file = document.querySelector(
          `input[name="gif_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah-gif_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.gif[index].file);

        this.$store.commit("templates/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.gif[index].file);
        templatesApi
          .templateImagesUpload(data)
          .then((res) => {
            this.formDataToSend.gifUrl[index].url = Object.values(res.data)[0].fileName;
            this.formDataToSend.gifUrl[index].gifId = Object.values(res.data)[0].imageId;
          })
          .catch((error) => {
            this.formDataToSend.gif[index].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      }, 0);
    },

    handleChoosePreview(index) {
      setTimeout(() => {
        this.formDataToSend.preview[index].file = document.querySelector(
          `input[name="preview_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah-preview_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.preview[index].file);

        this.$store.commit("templates/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.preview[index].file);
        templatesApi
          .templateImagesPreviewUpload(data)
          .then((res) => {
            console.log(res.data);
            data.append("image", this.formDataToSend.preview[index].file);
            this.formDataToSend.previewUrl[index].url = Object.values(res.data)[0].fileName;
            this.formDataToSend.previewUrl[index].imageId = Object.values(res.data)[0].imageId;
          })
          .catch((error) => {
            this.formDataToSend.preview[index].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      }, 0);
    },

    handleChooseSlider(index) {
      setTimeout(() => {
        this.formDataToSend.slider[index].file = document.querySelector(
          `input[name="slider_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah-slider_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.slider[index].file);

        this.$store.commit("templates/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.slider[index].file);
        templatesApi
          .templateImagesSliderUpload(data)
          .then((res) => {
            console.log(res.data);
            data.append("image", this.formDataToSend.slider[index].file);
            this.formDataToSend.sliderUrl[index].url = Object.values(res.data)[0].fileName;
            this.formDataToSend.sliderUrl[index].imageId = Object.values(res.data)[0].imageId;
          })
          .catch((error) => {
            this.formDataToSend.slider[index].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      }, 0);
    },

    handleLoadInstruction() {
      if (this.formDataToSend.fileUrl != null) {
        this.handleDeleteInstruction();
      }
      this.formDataToSend.file = document.querySelector(`input[name="instruction"]`).files[0];
      let data = new FormData();
      data.append("instruction", this.formDataToSend.file);
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .templateInstructionUpload(data)
        .then((res) => {
          this.formDataToSend.fileUrl = res.data.fileName;
        })
        .catch((error) => {
          console.log(error);
          this.formDataToSend.file = null;
          this.formDataToSend.fileUrl = null;
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },

    handleLoadVideo() {
      if (this.formDataToSend.videoUrl != null) {
        this.handleDeleteVideo();
      }
      this.formDataToSend.video = document.querySelector(`input[name="video"]`).files[0];
      let data = new FormData();
      data.append("video", this.formDataToSend.video);
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .templateVideoUpload(data)
        .then((res) => {
          this.formDataToSend.videoUrl = res.data.fileName;
          this.successUploadVideo = true;
          setTimeout(() => {
            this.successUploadVideo = false;
          }, 3000);
        })
        .catch((error) => {
          const { data } = error.response;
          if (data.errors) {
            this.$store.commit("templates/setValidationErrors", data.errors);
          }
          this.formDataToSend.video = null;
          this.formDataToSend.videoUrl = null;
          this.errorModal = true;
          this.errorModalText = "You can't upload Video. Error";
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },
    handleCreateEditorLayout() {
      const data = {
        template_id: this.$route.params.id,
      };
      try {
        templatesApi
          .createTemplateLayout(data)
          .then((res) => {
            console.log(res);
            this.$router.push({
              path: ROUTE.EDITOR.replace(":id", res.data.id),
            });
          })
          .catch((error) => {})
          .finally(() => {});
      } catch (e) {
        console.log(e);
      }
    },
    handleGoToEditorLayout() {
      const url = `/${this.$route.params.id}`;
      try {
        templatesApi
          .getTemplateLayoutById(url)
          .then((res) => {
            this.$router.push({
              path: ROUTE.EDITOR.replace(":id", res.data.id),
            });
          })
          .catch((error) => {})
          .finally(() => {});
      } catch (e) {
        console.log(e);
      }
    },

    handleDeleteVideo(cleaner = true) {
      this.deleteTurnVideo.push(this.formDataToSend.videoUrl);
      if (cleaner) {
        this.formDataToSend.video = null;
        this.formDataToSend.videoUrl = null;
      }
    },

    handleDeleteInstruction(cleaner = true) {
      this.deleteTurnInstruction.push(this.formDataToSend.fileUrl);
      if (cleaner) {
        this.formDataToSend.file = null;
        this.formDataToSend.fileUrl = null;
      }
    },

    handleDeleteSourceFile(index, cleaner = true) {
      if (this.formDataToSend.sizes[index].file_name != null) {
        this.deleteTurnSourceFile.push(this.formDataToSend.sizes[index].file_name);
        if (cleaner) {
          this.formDataToSend.sizes[index].file = null;
          this.formDataToSend.sizes[index].file_name = null;
        }
      }
    },

    setFormats(data) {
      const formats = data;
      for (let i = 0; formats.length > i; i++) {
        if (this.formDataToSend.formats[formats[i].id] == undefined) {
          this.$set(this.formDataToSend.formats, formats[i].id, false);
        }
      }
    },
    setColor(data) {
      const color = data;
      for (let i = 0; color.length > i; i++) {
        if (this.formDataToSend.color[color[i].id] == undefined) {
          this.$set(this.formDataToSend.color, color[i].id, false);
        }
      }
    },
    setTags(data) {
      const tags = data;
      for (let i = 0; tags.length > i; i++) {
        if (this.formDataToSend.tags[tags[i].id] == undefined) {
          this.$set(this.formDataToSend.tags, tags[i].id, false);
        }
      }
    },
    handleSearch(value) {
      this.search = value;
      this.getCategoriesList();
    },
    getCategoriesList() {
      this.$store.dispatch("categories/getCategoriesTree");
    },
    getSizes() {
      this.$store.dispatch("references/getTemplatesSizesAll");
    },
    getStatuses() {
      try {
        referencesApi.getTemplatesStatuses().then((res) => {
          const data = res.data[0].data;
          this.$store.commit("references/setTemplatesStatusesList", data);
          if (this.formDataToSend.status != null) {
            const status = this.templatesStatusesList.find(
              (x) => x.id == this.formDataToSend.status,
            );
            this.formDataToSend.status = status;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    getTypes() {
      this.$store.dispatch("references/getTemplatesTypes");
    },
    getStyle() {
      this.$store.dispatch("references/getTemplatesStyleAll");
    },
    getFormats() {
      try {
        referencesApi.getTemplatesFormats().then((res) => {
          const data = res.data[0].data;
          this.$store.commit("references/setTemplatesFormatsList", data);
          this.setFormats(data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    getColor() {
      try {
        referencesApi.getTemplatesColorAll().then((res) => {
          const data = res.data.items;
          this.$store.commit("references/setTemplatesColorList", data);
          this.setColor(data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    getTags() {
      try {
        referencesApi.getTemplatesTagsAll().then((res) => {
          const data = res.data.items;
          this.$store.commit("references/setTemplatesTagsList", data);
          this.setTags(data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    getFontsFilter(search) {
      this.search = search;
      const data = {
        search: search,
      };
      try {
        referencesApi.filterTemplatesFont(data).then((res) => {
          const data = res.data.items?.data;
          this.$store.commit("references/setTemplatesFontsList", data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    handleDeleteRow(index) {
      this.handleDeleteSourceFile(index);
      this.formDataToSend.sizes.splice(index, 1);
    },
    handleDeleteRowVideo(index) {
      this.formDataToSend.videos.splice(index, 1);
    },
    handleAddRow() {
      this.formDataToSend.sizes.push({
        name: "",
        size: null,
        src: "",
        file: null,
        file_name: null,
      });
    },
    handleAddRowVideo() {
      this.formDataToSend.videos.push({
        title: "",
        url: "",
        id: Math.floor(Math.random() * 10000),
      });
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.TEMPLATES_LIST.replace(":page", 1),
      });
    },
    getLang() {
      let url = `?page=${this.page}`;
      this.$store.dispatch("lang/getLangList", url);
    },
    closeLangModal() {
      this.chosenLang = null;
      this.langModal = false;
      this.formDataToSendLang.name = "";
      this.formDataToSendLang.annotation = "";
      this.langEdit = false;
    },
    closeKeywordModal() {
      this.keywordModal = false;
    },
    openKeywordModal() {
      this.keywordModal = true;
    },
    openLangModal(lang) {
      this.langModal = true;
      this.chosenLang = lang;
      const translate = this.template.translation;
      let translateTemplate = translate.find((template) => template.langId === this.chosenLang.id);
      console.log(translateTemplate);
      if (translateTemplate) {
        this.formDataToSendLang.name = translateTemplate.name;
        this.formDataToSendLang.annotation = translateTemplate.annotation;
        this.formDataToSendLang.id = translateTemplate.id;
        this.langEdit = true;
      }
    },
    handleSubmitLang() {
      this.$store.commit("references/setLoading", true);
      const data = {
        name: this.formDataToSendLang.name,
        annotation: this.formDataToSendLang.annotation,
        template_id: this.template.id,
        lang_id: this.chosenLang.id,
      };
      if (this.langEdit) {
        langApi
          .editLangTemplate(data, `/${this.formDataToSendLang.id}`)
          .then(() => {
            this.$store.commit("references/setLoading", false);
            this.closeLangModal();
            this.getItemTemplate();
          })
          .catch((error) => {
            this.$store.commit("references/setLoading", false);
            console.log(error);
          });
      } else {
        langApi
          .createLangTemplate(data)
          .then(() => {
            this.$store.commit("references/setLoading", false);
            this.closeLangModal();
            this.getItemTemplate();
          })
          .catch((error) => {
            this.$store.commit("references/setLoading", false);
            console.log(error);
          });
      }
    },
    handleTranslate(el, lang) {
      this.$store.commit("references/setLoading", true);
      const data = {
        text: this.formDataToSend[el],
        language: lang,
      };
      langApi
        .getTranslation(data)
        .then((res) => {
          this.$store.commit("references/setLoading", false);
          this.formDataToSendLang[el] = res.data.response;
        })
        .catch((error) => {
          this.$store.commit("references/setLoading", false);
          console.log(error);
        });
    },
    getItemTemplate() {
      this.$store.commit("templates/setLoading", true);
      const url = `/${this.getId}`;
      try {
        templatesApi.getItemTemplate(url).then((res) => {
          this.$store.commit("templates/setError", {});
          this.$store.commit("templates/setValidationErrors", {});
          const data = res.data;
          this.template = res.data;
          this.history = res.data.history[0];
          console.log(res.data.contentPlanItem);
          if (res.data.contentPlanItem) {
            this.keyword = res.data.contentPlanItem.keyword;
          }
          if (data) {
            this.formDataToSend.name = data.name;
            this.formDataToSend.seo_name = data.seoName;
            this.formDataToSend.everPublished = data.everPublished;
            this.formDataToSend.annotation = data.annotation;
            this.formDataToSend.status = data.statusId;
            this.formDataToSend.types = data.typeId;
            this.formDataToSend.style = data.styleId;
            this.formDataToSend.premium = data.premium;
            this.formDataToSend.noindex = data.noindex;
            this.formDataToSend.gif = [];
            this.formDataToSend.gifUrl = [];
            this.formDataToSend.preview = [];
            this.formDataToSend.previewUrl = [];
            this.formDataToSend.img = [];
            this.formDataToSend.imgUrl = [];
            this.formDataToSend.slider = [];
            this.formDataToSend.sliderUrl = [];
            this.formDataToSend.categories = null;
            this.formDataToSend.categoriesParent = null;
            this.formDataToSend.categoriesLevel = [];
            this.formDataToSend.categoriesLevelName = [];

            if (data.publishedAt != null) {
              this.formDataToSend.date = moment.utc(data.publishedAt).format("DD.MM.YYYY HH:mm:ss");
            }

            if (data.fileName != null) {
              this.formDataToSend.oldFileName = data.fileName;
            }

            const categories = data.categories || [];
            if (categories.length === 1) {
              this.formDataToSend.categories = categories[0].categoryId;
            } else if (categories.length > 1) {
              for (let i = 0; categories.length > i; i++) {
                this.formDataToSend.categoriesLevel.push(categories[i].categoryId);
                this.formDataToSend.categoriesLevelName.push(
                  `${categories[i].name} (${categories[i].depth})`,
                );
              }
            }

            const tags = data.tags;
            if (tags != undefined && tags.length > 0) {
              for (let i = 0; tags.length > i; i++) {
                this.$set(this.formDataToSend.tags, tags[i].tagId, true);
              }
            }

            const color = data.colors;
            if (color != undefined && color.length > 0) {
              for (let i = 0; color.length > i; i++) {
                this.$set(this.formDataToSend.color, color[i].id, true);
              }
            }

            this.formDataToSend.fonts = data.fonts;

            const formats = data.formats;
            if (formats != undefined && formats.length > 0) {
              for (let i = 0; formats.length > i; i++) {
                this.$set(this.formDataToSend.formats, formats[i].id, true);
              }
            }

            if (data.sources != undefined && data.sources.length > 0) {
              const sources = data.sources[0];
              const sourcesForm = [];
              if (sources.length > 0) {
                for (let i = 0; sources.length > i; i++) {
                  sourcesForm.push({
                    name: sources[i].name,
                    size: sources[i].size,
                    size_id: sources[i].sizeId,
                    src: sources[i].source,
                    id: sources[i].id,
                    file: sources[i].fileName,
                    file_name: sources[i].fileName,
                  });
                }
                this.formDataToSend.sizes = sourcesForm;
              }
            }

            if (data.videos != undefined && data.videos.length > 0) {
              const videos = data.videos[0];
              const videosForm = [];
              if (videos.length > 0) {
                for (let i = 0; videos.length > i; i++) {
                  videosForm.push({
                    title: videos[i].title,
                    url: videos[i].url,
                    id: Math.floor(Math.random() * 10000),
                  });
                }
                this.formDataToSend.videos = videosForm;
              }
            }

            const slideImages = data.slideImages;

            if (slideImages.length > 0) {
              for (let i = 0; slideImages[0].length > i; i++) {
                this.formDataToSend.slider.push({
                  file: null,
                  id: Math.floor(Math.random() * 10000),
                });
                this.formDataToSend.sliderUrl.push({
                  url: slideImages[0][i].image,
                  urlSlider: slideImages[0][i].url.preview[0],
                  imageId: slideImages[0][i].id,
                });
              }
            }

            const images = data.images;
            if (images.length > 0) {
              for (let i = 0; images[0].length > i; i++) {
                if (images[0][i].gif) {
                  this.formDataToSend.gif.push({
                    file: null,
                    id: Math.floor(Math.random() * 10000),
                  });
                  this.formDataToSend.gifUrl.push({
                    url: images[0][i].image,
                    urlGif: images[0][i].url.preview[0],
                    gifId: images[0][i].id,
                  });
                } else if (images[0][i].preview) {
                  this.formDataToSend.preview.push({
                    file: null,
                    id: Math.floor(Math.random() * 10000),
                  });
                  this.formDataToSend.previewUrl.push({
                    url: images[0][i].image,
                    urlPreview: images[0][i].url.preview[0],
                    imageId: images[0][i].id,
                  });
                } else {
                  this.formDataToSend.img.push({
                    file: null,
                    id: Math.floor(Math.random() * 10000),
                  });
                  this.formDataToSend.imgUrl.push({
                    url: images[0][i].image,
                    urlImg: images[0][i].url.preview[0],
                    imageId: images[0][i].id,
                  });
                }
              }
            } else {
              this.formDataToSend.img.push({
                file: null,
                id: Math.floor(Math.random() * 10000),
              });
              this.formDataToSend.imgUrl.push({
                url: null,
              });
            }

            if (data.contentPlanItem) {
              if (data.contentPlanItem.itemTypeId == 4) {
                this.formDataToSend.secondaryStatus = data.secondaryStatusId;
              }
            }

            const instruction = data.instruction;
            if (instruction != null) {
              this.formDataToSend.file = instruction;
              this.formDataToSend.fileUrl = instruction;
            }

            const video = data.video;
            if (video != null) {
              this.formDataToSend.video = video;
              this.formDataToSend.videoUrl = video;
            }
          }
          this.getTags();
          this.getFormats();
          this.getStatuses();
          this.getColor();
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("templates/setLoading", false);
      }
    },
    handleAddImage() {
      if (
        this.formDataToSend.img.length == 1 ||
        this.formDataToSend.img.length == 0 ||
        this.formDataToSend.img[this.formDataToSend.img.length - 1].file != null ||
        this.formDataToSend.imgUrl[this.formDataToSend.imgUrl.length - 1].url != null
      ) {
        this.formDataToSend.img.push({
          file: null,
          id: Math.floor(Math.random() * 10000),
        });
        this.formDataToSend.imgUrl.push({
          url: null,
          urlImg: null,
        });
      }
    },
    handleAddImagePreview() {
      if (
        this.formDataToSend.preview.length == 1 ||
        this.formDataToSend.preview.length == 0 ||
        this.formDataToSend.preview[this.formDataToSend.preview.length - 1].file != null ||
        this.formDataToSend.previewUrl[this.formDataToSend.previewUrl.length - 1].url != null
      ) {
        this.formDataToSend.preview.push({
          file: null,
          id: Math.floor(Math.random() * 10000),
        });
        this.formDataToSend.previewUrl.push({
          url: null,
          urlImg: null,
        });
      }
    },
    handleAddImageGif() {
      if (
        this.formDataToSend.gif.length == 1 ||
        this.formDataToSend.gif.length == 0 ||
        this.formDataToSend.gif[this.formDataToSend.gif.length - 1].file != null ||
        this.formDataToSend.gifUrl[this.formDataToSend.gifUrl.length - 1].url != null
      ) {
        this.formDataToSend.gif.push({
          file: null,
          id: Math.floor(Math.random() * 10000),
        });
        this.formDataToSend.gifUrl.push({
          url: null,
          urlGif: null,
        });
      }
    },
    handleAddImageSlider() {
      if (
        this.formDataToSend.slider.length == 1 ||
        this.formDataToSend.slider.length == 0 ||
        this.formDataToSend.slider[this.formDataToSend.slider.length - 1].file != null ||
        this.formDataToSend.sliderUrl[this.formDataToSend.sliderUrl.length - 1].url != null
      ) {
        this.formDataToSend.slider.push({
          file: null,
          id: Math.floor(Math.random() * 10000),
        });
        this.formDataToSend.sliderUrl.push({
          url: null,
          urlImg: null,
        });
      }
    },
    handleDeleteImage(index) {
      if (this.formDataToSend.imgUrl[index].url != null) {
        this.deleteTurn.push(this.formDataToSend.imgUrl[index].imageId);
      }
      this.formDataToSend.img.splice(index, 1);
      this.formDataToSend.imgUrl.splice(index, 1);
    },
    handleChangeImage(index) {
      this.deleteTurn.push(this.formDataToSend.imgUrl[index].imageId);
      this.formDataToSend.img[index].file = null;
      this.formDataToSend.imgUrl[index].url = null;
      this.formDataToSend.imgUrl[index].urlImg = null;
    },
    handleChangePreview(index) {
      this.deleteTurn.push(this.formDataToSend.previewUrl[index].imageId);
      this.formDataToSend.preview[index].file = null;
      this.formDataToSend.previewUrl[index].url = null;
      this.formDataToSend.previewUrl[index].urlPreview = null;
    },
    handleDeletePreview(index) {
      if (this.formDataToSend.previewUrl[index].url != null) {
        this.deleteTurn.push(this.formDataToSend.previewUrl[index].imageId);
      }
      this.formDataToSend.preview.splice(index, 1);
      this.formDataToSend.previewUrl.splice(index, 1);
    },
    handleDeleteGif(index) {
      if (this.formDataToSend.gifUrl[index].url != null) {
        this.deleteTurnGif.push(this.formDataToSend.gifUrl[index].url);
      }
      this.formDataToSend.gif.splice(index, 1);
      this.formDataToSend.gifUrl.splice(index, 1);
    },
    handleChangeGif(index) {
      this.deleteTurnGif.push(this.formDataToSend.gifUrl[index].url);
      this.formDataToSend.gif[index].file = null;
      this.formDataToSend.gifUrl[index].url = null;
      this.formDataToSend.gifUrl[index].urlGif = null;
    },
    handleChangeSlider(index) {
      this.deleteTurn.push(this.formDataToSend.sliderUrl[index].imageId);
      this.formDataToSend.slider[index].file = null;
      this.formDataToSend.sliderUrl[index].url = null;
      this.formDataToSend.sliderUrl[index].urlSlider = null;
    },
    handleDeleteSlider(index) {
      if (this.formDataToSend.sliderUrl[index].url != null) {
        this.deleteTurn.push(this.formDataToSend.sliderUrl[index].imageId);
      }
      this.formDataToSend.slider.splice(index, 1);
      this.formDataToSend.sliderUrl.splice(index, 1);
    },
    handleCloseSuccessModal() {
      this.successModal = false;
      // this.handleDeleteTurnImages();
      this.handleDeleteTurnInstructions();
      this.handleDeleteTurnSourceFile();
      this.handleDeleteTurnGif();
      this.handleDeleteTurnVideo();
      setTimeout(() => {
        this.handleBackToList();
      }, 1000);
    },
    handleDeleteTurnGif() {
      if (this.deleteTurnGif.length > 0) {
        for (let i = 0; this.deleteTurnGif.length > i; i++) {
          const data = {
            fileName: this.deleteTurnGif[i],
          };
          console.log(data);
          templatesApi
            .templateImagesDelete(data)
            .then((res) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    handleDeleteTurnVideo() {
      if (this.deleteTurnVideo.length > 0) {
        for (let i = 0; this.deleteTurnVideo.length > i; i++) {
          const data = {
            video: this.deleteTurnVideo[i],
          };
          console.log(data);
          templatesApi
            .templateVideoDelete(data)
            .then((res) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    handleDeleteTurnInstructions() {
      if (this.deleteTurnInstruction.length > 0) {
        for (let i = 0; this.deleteTurnInstruction.length > i; i++) {
          const data = {
            instruction: this.deleteTurnInstruction[i],
          };
          templatesApi
            .templateInstructionDelete(data)
            .then((res) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    handleDeleteTurnSourceFile() {
      if (this.deleteTurnSourceFile.length > 0) {
        for (let i = 0; this.deleteTurnSourceFile.length > i; i++) {
          const url = `?path=${this.deleteTurnSourceFile[i]}`;
          templatesApi
            .templateSourceFileDelete(url)
            .then((res) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    getImageSizes() {
      templatesApi
        .templateImageSizes()
        .then((res) => {
          this.imageSize = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setSeoName(data) {
      const value = seoTransform(data);
      this.formDataToSend.seo_name = value;
      this.handleCheckSeoName();
    },
    handleCheckSeoName() {
      if (this.formDataToSend.seo_name.length > 0) {
        const url = `/${this.formDataToSend.seo_name.trim()}`;
        this.$store.dispatch("templates/templateCheckSeo", url);
      }
    },
    handleLead(id) {
      this.formDataToSend.status.id = id;
      this.commentModal = true;
    },
    handleLeadUpdate(id) {
      this.formDataToSend.secondaryStatus = id;
      this.commentModal = true;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.mx-datepicker {
  width: 100%;
}
.checkbox-container__item {
  padding: 5px 0;
}

.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}

/deep/.textarea-input {
  textarea {
    height: 240px;
    resize: vertical;
  }
}

/deep/.preview-container {
  margin: 0;
}

.keyword-modal {
  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  &__list-item {
    font-size: 18px;
    padding-bottom: 10px;

    &-req {
      padding: 10px;
      background: rgb(192, 192, 192);
      margin-top: 20px;
      border-radius: 5px;
    }

    ul {
      padding-left: 30px;
    }

    li {
      padding-bottom: 5px;
    }
  }
}

.form-create {
  &__comments {
    padding-top: 50px;
  }

  &__label-span {
    background: $mainSecondColor;
    color: #fff;
    display: block;
    font-size: 12px;
    padding: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
  }

  &__label-spans {
    display: flex;
    flex-wrap: wrap;
  }

  &__button-lead {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
  }

  &__button-lead-approve {
    background: rgba(0, 128, 0, 0.63);
    margin-right: 10px;
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 700;
    color: #fff;

    &:hover {
      opacity: 0.8;
    }
  }

  &__button-lead-reject {
    background: rgba(255, 0, 0, 0.685);
    margin-right: 10px;
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 700;
    color: #fff;

    &:hover {
      opacity: 0.8;
    }
  }

  &__button-lead-keyword {
    background: rgba(92, 92, 92, 0.685);
    margin-right: 10px;
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 700;
    color: #fff;
    width: fit-content;
    margin-bottom: 15px;

    &:hover {
      opacity: 0.8;
    }
  }

  &__comments-title {
    font-size: 30px;
    padding-bottom: 20px;
  }

  &__comment {
    margin-bottom: 20px;
    background: gainsboro;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  &__comment-date {
    margin-left: auto;
    padding-bottom: 20px;
  }

  &__comment-item {
    padding-bottom: 10px;
  }

  &__images {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__file-ftp {
    max-width: 500px;
  }

  &__btn {
    padding-bottom: 20px;
  }

  &__image {
    position: relative;
    width: 220px;
    height: 220px;
    margin: 0 30px 45px 0;
    border: 1px solid $mainDisabled;
  }

  &__delete-image {
    position: absolute;
    top: -19px;
    right: -26px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  &__add-image {
    position: absolute;
    top: -19px;
    right: -8px;
    cursor: pointer;
    transform: rotate(45deg);

    svg {
      path {
        fill: $mainSecondColor;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__image-load {
    width: 220px;
    height: 220px;
    object-fit: cover;
  }

  &__change-image {
    position: absolute;
    bottom: -40px;
    left: 44%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: $mainColor;
    border-radius: 50px;
    cursor: pointer;

    svg {
      path {
        fill: $g-color-white;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
.upload-instruction {
  display: flex;

  &__button {
    margin-right: 20px;
  }
}
</style>
